@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-ExtraBoldItalic.eot');
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
  url('./assets/fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
  url('./assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('./assets/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-SemiBold.woff') format('woff'),
  url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('./assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-Regular.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-Regular.woff') format('woff'),
  url('./assets/fonts/OpenSans-Regular.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('./assets/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-Italic.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-Italic.woff') format('woff'),
  url('./assets/fonts/OpenSans-Italic.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Medium.eot');
  src: local('Open Sans Medium'), local('OpenSans-Medium'),
  url('./assets/fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-Medium.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-Medium.woff') format('woff'),
  url('./assets/fonts/OpenSans-Medium.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-SemiBoldItalic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('./assets/fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
  url('./assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('./assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-Light.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-Light.woff') format('woff'),
  url('./assets/fonts/OpenSans-Light.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('./assets/fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-BoldItalic.woff') format('woff'),
  url('./assets/fonts/OpenSans-BoldItalic.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('./assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-Bold.woff') format('woff'),
  url('./assets/fonts/OpenSans-Bold.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-ExtraBold.eot');
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('./assets/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-ExtraBold.woff') format('woff'),
  url('./assets/fonts/OpenSans-ExtraBold.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-LightItalic.eot');
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('./assets/fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-LightItalic.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-LightItalic.woff') format('woff'),
  url('./assets/fonts/OpenSans-LightItalic.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-MediumItalic.eot');
  src: local('Open Sans Medium Italic'), local('OpenSans-MediumItalic'),
  url('./assets/fonts/OpenSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/OpenSans-MediumItalic.woff2') format('woff2'),
  url('./assets/fonts/OpenSans-MediumItalic.woff') format('woff'),
  url('./assets/fonts/OpenSans-MediumItalic.ttf') format('truetype'),
  url('./assets/fonts/OpenSans-MediumItalic.svg#OpenSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Black.eot');
  src: local('Roboto Slab Black'), local('RobotoSlab-Black'),
  url('./assets/fonts/RobotoSlab-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-Black.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-Black.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-Black.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-Black.svg#RobotoSlab-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-SemiBold.eot');
  src: local('Roboto Slab SemiBold'), local('RobotoSlab-SemiBold'),
  url('./assets/fonts/RobotoSlab-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-SemiBold.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-SemiBold.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-SemiBold.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-SemiBold.svg#RobotoSlab-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-ExtraLight.eot');
  src: local('Roboto Slab ExtraLight'), local('RobotoSlab-ExtraLight'),
  url('./assets/fonts/RobotoSlab-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-ExtraLight.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-ExtraLight.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-ExtraLight.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-ExtraLight.svg#RobotoSlab-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Light.eot');
  src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
  url('./assets/fonts/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-Light.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-Light.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-Light.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-Light.svg#RobotoSlab-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Regular.eot');
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
  url('./assets/fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-Regular.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-Regular.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-Regular.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-ExtraBold.eot');
  src: local('Roboto Slab ExtraBold'), local('RobotoSlab-ExtraBold'),
  url('./assets/fonts/RobotoSlab-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-ExtraBold.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-ExtraBold.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-ExtraBold.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-ExtraBold.svg#RobotoSlab-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Medium.eot');
  src: local('Roboto Slab Medium'), local('RobotoSlab-Medium'),
  url('./assets/fonts/RobotoSlab-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-Medium.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-Medium.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-Medium.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-Medium.svg#RobotoSlab-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Thin.eot');
  src: local('Roboto Slab Thin'), local('RobotoSlab-Thin'),
  url('./assets/fonts/RobotoSlab-Thin.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-Thin.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-Thin.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-Thin.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-Thin.svg#RobotoSlab-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Bold.eot');
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
  url('./assets/fonts/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RobotoSlab-Bold.woff2') format('woff2'),
  url('./assets/fonts/RobotoSlab-Bold.woff') format('woff'),
  url('./assets/fonts/RobotoSlab-Bold.ttf') format('truetype'),
  url('./assets/fonts/RobotoSlab-Bold.svg#RobotoSlab-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


.shadow-home{
  box-shadow: 0 6px 13px rgba(0,0,0,.5) !important;
}
/*.pswp__scroll-wrap > .pswp__container > .pswp__item > .pswp__img {*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  max-height: calc(100vh - 100px);*/
/*  width: 100%;*/
/*  height: auto;*/
/*}*/

/* Stili per il lightbox */
.pswp {
  display: block !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  overflow: hidden !important;
  z-index: 10000 !important;
}

/* Stili per l'elemento img all'interno del lightbox */
.pswp__img {
  width: auto;
  object-fit: contain;
  z-index: 10001;
}

/* Stili per l'elemento genitore del lightbox */
.pswp__container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10000;
}


.pswp__button--arrow--prev, .pswp__button--arrow--next {
  z-index:  10002 !important;
}




@media(max-width: 992px){

  .pswp__button--arrow--prev, .pswp__button--arrow--next {
    z-index:  10002 !important;
  }
  .pswp__top-bar {
  }
  .pswp__top-bar.pswp__hide-on-close {
    background: #00577C!important;
  }
  .pswp__counter {
    /*font-size: 30px!important;*/
  }
  .pswp__button.pswp__button--close svg {
    /*width: 60px!important;*/
    /*height: 60px!important;*/
    /*top: 0px!important;*/
    /*left: 0px!important;*/
  }
}
@media(min-width: 992px){

  .pswp__button--arrow--prev, .pswp__button--arrow--next {
    z-index:  10002 !important;
  }
  .pswp__top-bar {
    top: initial!important;
    bottom: 0px!important;
  }
  .pswp__top-bar.pswp__hide-on-close {
    background: #00577C!important;
  }
  .pswp__counter {
    font-size: 30px!important;
  }
  .pswp__button.pswp__button--close svg {
    width: 60px!important;
    height: 60px!important;
    top: 0px!important;
    left: 0px!important;
  }
}
.leaflet-container .leaflet-marker-pane img {
  width: 15px!important;
}


.camerascan {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  /* height: auto!important; */
  /* width: auto!important; */
  background-color: black;
  /* transform: translate(-50%, -50%); */
  object-fit: cover;
}

.pswp__top-bar.pswp__hide-on-close {
  z-index: 99999999!important;
}